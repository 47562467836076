<template>
    <div>
        
        <!-- Diagnósticos de enfermería -->
        <v-toolbar short dense color="primary" dark flat>

            <v-toolbar-title>Diagnósticos de enfermería</v-toolbar-title>
            
            <v-progress-linear
                v-if="loadingDatos"
                :active="loadingDatos"
                :indeterminate="loadingDatos"
                absolute
                bottom
                color="green"
            ></v-progress-linear>

            <v-spacer></v-spacer>

            


             <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon @click="cargaDiagnosticosTodos" :disabled="muestraMat" v-on="on">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <span>Recargar</span>
            </v-tooltip>
            
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn  dark rounded v-on="on" :disabled="muestraMat" color="blue">
                        <v-icon>mdi-note-plus-outline</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                        v-for="(item, index) in datosTurnos"
                        :key="index"
                        @click="nuevoDiag(item)"
                    >
                        <v-list-item-title :class="item.color+'--text'">{{ item.text }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            
        </v-toolbar>

        <agrega-enfermeria-diagnosticos
            
            :muestra.sync="muestraMat"
            :esteTurno="esteTurnoDiag"
            :estaFecha="estaFecha"
            :internamiento="internamiento"
            @diagnosticoSuccess="cargaDiagnosticosTodos"
        />

        <v-expand-transition>
            <v-alert
                v-if="sinRegistros && !loadingDatos"
                class="my-1"
                border="left"
                dense
                text
                type="info"
            >
                No hay registos para este día
            </v-alert>
        </v-expand-transition>

        <v-expand-transition>
            <enfermeria-diagnosticos
                v-if="!sinRegistros"
                :datos="datosDiag"
                @borrado="cargaDiagnosticosTodos"
            />
        </v-expand-transition>
           
        <!-- Resultados obtenidos -->
        <v-toolbar short dense color="primary" dark flat>

            <v-toolbar-title>Resultados obtenidos</v-toolbar-title>
            
            
            
            <v-spacer></v-spacer>
            
            
        </v-toolbar>

        <v-row class="px-1">
            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <resultado-detalle
                    turno="mat"
                    :estaFecha="estaFecha"
                    :internamiento="internamiento"
                />
            </v-col>
            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <resultado-detalle
                    turno="ves"
                    :estaFecha="estaFecha"
                    :internamiento="internamiento"
                />
            </v-col>
            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <resultado-detalle
                    turno="noc"
                    :estaFecha="estaFecha"
                    :internamiento="internamiento"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import EnfermeriaDiagnosticos from '@/components/hospital/enfe/diagnosticos/DiagnosticosEnfermeria'
import AgregaEnfermeriaDiagnosticos from '@/components/hospital/enfe/diagnosticos/AgregaDiagnostico'
import ResultadoDetalle from '@/components/hospital/enfe/diagnosticos/Resultados'
//import IntervencionesDetalles from '@/components/hospital/enfe/diagnosticos/IntervencionesDetalles'

export default {
    created (){
        
        this.cargaDiagnosticosTodos()
    },
    components:{
        'enfermeria-diagnosticos':EnfermeriaDiagnosticos,
        'agrega-enfermeria-diagnosticos':AgregaEnfermeriaDiagnosticos,
        //'intervenciones-detalles': IntervencionesDetalles,
        'resultado-detalle':ResultadoDetalle,
    },
    props:{
        estaFecha: String,
        internamiento: Number,
    },
    data:()=>({
        muestraDiagnosticos:false,
        muestraIntervenciones:false,
        muestraMat:false,
        muestraResul:false,
        
        datosDiag:{
            diag:[],
            res:[],
            int:[],
        },

        datosTurnos:[
            {val:'mat', text: 'Matutino', color:'blue'},
            {val:'ves', text: 'Vespertino', color:'green'},
            {val:'noc', text: 'Nocturno', color:'red'},
        ],
        datosResul:[
            {val:'mat', text: 'Matutino', color:'blue'},
            {val:'ves', text: 'Vespertino', color:'green'},
            {val:'noc', text: 'Nocturno', color:'red'},
        ],

        esteTurnoDiag:{},
        loadingDatos:false,


    }),
    methods:{
        async cargaDiagnosticosTodos(){

            //this.loadingDatosEscalas = true
            this.loadingDatos = true
            let me = this

            this.datosDiag={
                diag:[],
                res:[],
                int:[],
            }

            let params = {
                internamiento: this.internamiento,
                fecha: this.estaFecha
            }
            try {
                // Obtiene todos los datos (datos crudos)
                let diagnos = await this.$http({
                    url:'/enfermeria/cargaDiagnosticos',
                    method:'GET',
                    params: params,
                })

                if (diagnos.data.estado == true){
                    
                    let arrayEstados = []
                    // -----------------
                    // Filtra los obtenidos para cada escala
                    diagnos.data.root.forEach(dt => {
                        arrayEstados.push(dt.tipo)
                    });
                    // Este es el que filtra
                    arrayEstados = Array.from(new Set(arrayEstados))
                    
                    // Busca en en los datos crudos por los que correspondan para agregarlos
                    arrayEstados.forEach(dt =>{
                        
                        // Limpia el array para obtener nuevos datos
                        me.datosDiag[dt] = []

                        diagnos.data.root.forEach(dt2 =>{
                            //datosEscalas
                            if (dt == dt2.tipo)
                            me.datosDiag[dt].push(dt2)
                        })
                    })
                }
                this.loadingDatos = false
            } catch (err){
                console.log(err)
                this.loadingDatos = false
                //this.loadingDatosEscalas = false
                this.$store.dispatch('security/revisaError',err)
            }
            
        },
        cargaResultadosTodos(){
            //
        },
        nuevoDiag(dt){
            //console.log(dt)
            this.esteTurnoDiag=dt
            this.muestraMat=true
        },
        nuevoRes(dt){
            console.log(dt)
        },
    },
    computed:{
        sinRegistros(){
            if (this.datosDiag.diag.length == 0 && this.datosDiag.diag.length == 0 && this.datosDiag.diag.length == 0){
                return true
            } else {
                return false
            }
        }
    },
    watch:{
        estaFecha(){
                //Carga la acción para cargar estados
                this.cargaDiagnosticosTodos()
            },
    }

}
</script>

<style>

</style>