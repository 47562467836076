<template>
    <div>
        <div v-if="muestra" :class="esteTurno.color+' lighten-5'" class="pa-1">
            <span>Para <b>{{moment(estaFecha, 'DD-MM-YYYY').format('dddd MMMM D, YYYY')}}</b> turno <b>{{esteTurno.text}}</b></span><br/>
            <span v-if="esteTurno.val == 'noc'" class="red--text">En caso de turno nocturno pasada la media noche: <b>RECUERDE UTILIZAR EL DÍA ANTERIOR</b></span>
        </div>
        <div v-if="muestra" class="px-2 d-flex flex-row-reverse " :class="esteTurno.color+' lighten-5'">
            <v-btn  @click="guarda" text color="success" :loading="loading">Guardar</v-btn>
            <v-btn  @click="cancela" text color="error">Cancelar</v-btn>
        </div>
        <v-expand-transition>
            <v-row v-if="muestra" no-gutters :class="esteTurno.color+' lighten-5 pb-5'">
                <v-col class="px-2" xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                        <v-textarea
                            v-model="formDiag.diag"
                            class="pt-4"
                            outlined
                            filled
                            dense
                            hide-details
                            background-color="white"
                            label="Diagnósticos de enfermería"
                        ></v-textarea>
                </v-col>
                <v-col class="px-2" xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                        <v-textarea
                            v-model="formDiag.res"
                            class="pt-4"
                            outlined
                            filled
                            dense
                            hide-details
                            background-color="white"
                            label="Resultados esperados"
                        ></v-textarea>
                </v-col>
                <v-col class="px-2" xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                        <v-textarea
                            v-model="formDiag.int"
                            class="pt-4"
                            outlined
                            dense
                            hide-details
                            background-color="white"
                            label="Intervenciones y/o recomendaciones de las G.P.C.E."
                        ></v-textarea>
                </v-col>
                
            </v-row>
        </v-expand-transition>
    </div>
</template>

<script>
export default {
    props:{
        muestra:Boolean,
        esteTurno:Object,
        estaFecha: String,
        internamiento: Number,
    },
    data:()=>({
        loading:false,
        formDiag:{
            diag:'',
            res:'',
            int:'',
        },
        todo:false,
    }),
    methods:{
        async guarda(){
            this.loading = true
            let da
            let diagDat = {
                internamiento: this.internamiento,
                fecha: this.estaFecha,
                turno: this.esteTurno.val,
            }

            // ----------------------------------------------
            // For para el array de cosas quese van a guardar
            // Usa async await
            // -----------------------------------------------

            for(da of Object.keys(this.formDiag)){
                if (this.formDiag[da]){
                    diagDat.tipo = da
                    diagDat.contenido = this.formDiag[da]
                    try {
                        let dia = await this.$http({
                            method:'POST',
                            url:'/enfermeria/guardaDiagnosticos',
                            data: diagDat
                        })
                        if (dia.data.estado == false){
                            console.log(dia.data.root)
                            this.loading = false
                            this.$swal.fire({
                                icon: 'warning',
                                title: 'No se puede guardar',
                                text: 'Verifique los datos e intente de nuevo',
                            })
                            this.todo = false
                            break
                        }

                    } catch(err){
                        this.loading = false
                        this.todo = false
                        console.log(err)
                        this.$store.dispatch('security/revisaError',err)
                        break
                    }
                }
                this.todo = true
            }
            this.loading = false

            if (this.todo == true){
                this.formDiag.diag=''
                this.formDiag.res=''
                this.formDiag.int=''

                this.$emit('update:muestra', false)
                this.$emit('diagnosticoSuccess' )
            }
        },
        cancela(){
            this.formDiag.diag=''
            this.formDiag.res=''
            this.formDiag.int=''
            this.$emit('update:muestra', false)
        },
        
    },

}
</script>

<style>

</style>