<template>
    
    <v-sheet elevation="2" width="100%">
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Diagnósticos de enfermería</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Resultados esperados</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Intervenciones y/o recomendaciones de las G.P.C.E.</span></th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="mb-3" v-for="item in datosTable" :key="item.id">
                        <td width="33%" class="textoarriba tablaDiag" >
                            <div v-if="item.diag.id!= '-'">
                                <v-icon
                                    small
                                    color="red lighten-3"
                                    v-if="tieneRol('ROLE_ENFERMERIA_ADMIN')||tieneRol('ROLE_ADMIN')"
                                    @click="borraEntrada(item.diag.id)">
                                        mdi-eraser
                                </v-icon>
                            </div>
                            <div class="caption text-justify py-2" :style="{color:getColor(item.diag)}" @click="muestraDatos(item.diag)">
                                <span style="white-space: pre-wrap">{{item.diag.contenido}}</span>
                            </div>
                            
                        </td>
                        
                        <td width="33%" class="textoarriba tablaDiag" >
                            <div v-if="item.res.id!= '-'">
                                <v-icon
                                    small
                                    color="red lighten-3"
                                    v-if="tieneRol('ROLE_ENFERMERIA_ADMIN')||tieneRol('ROLE_ADMIN')"
                                    @click="borraEntrada(item.res.id)">
                                        mdi-eraser
                                </v-icon>
                            </div>
                            <div class="caption text-justify py-2" :style="{color:getColor(item.res)}" @click="muestraDatos(item.res)">
                                <span style="white-space: pre-wrap">{{item.res.contenido}}</span>
                            </div>
                        </td>
                        
                        <td width="33%" class="textoarriba tablaDiag" >
                            
                            <div v-if="item.int.id != '-'">
                                <v-icon
                                    small
                                    color="red lighten-3"
                                    v-if="tieneRol('ROLE_ENFERMERIA_ADMIN')||tieneRol('ROLE_ADMIN')"
                                    @click="borraEntrada(item.int.id)">
                                        mdi-eraser
                                </v-icon>
                            </div>
                            <div class="caption text-justify py-2" :style="{color:getColor(item.int)}" @click="muestraDatos(item.int)">
                                <span style="white-space: pre-wrap">{{item.int.contenido}}</span>
                            </div>
                            
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-sheet>
    
</template>

<script>
export default {
    props:{
        datos:Object,
    },
    data:()=>({
        
    }),
    methods:{

        tieneRol(rol){
            let contiene = this.$store.state.security.roles.includes(rol)
            return (contiene)
        },

        async borra2(id,motivo){
            try{
                let borra = await this.$http({
                    method:'DELETE',
                    url:'/enfermeria/borraDiagnostico',
                    data:{id:id, motivo:motivo}
                })
                if (borra.data.estado === true){
                    this.$emit('borrado')
                } else {
                    console.log(borra.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede eliminar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        borraEntrada(id){
            //let motivo 
            this.$swal.fire({
                title: 'Borrar esta entrada',
                text: 'Motivo para borrar esta entrada',
                input: 'text',
                //inputValue:motivo,
                confirmButtonText: 'Borrar registro',
                showLoaderOnConformation: true,
                inputValidator:  (value) => {
                    if (value) {

                        this.$swal.fire({
                            title:'¿Desea borrar?',
                            text:'Esta acclón no se puede deshacer',
                            icon: 'warning',
                            showCancelButton: true
                        }).then((result)=>{
                            if (result.value){
                                this.borra2(id,value)
                            }
                        })
                    } else {
                        return 'Indique el motivo para esta eliminación'
                    }
                }
            })
        },

        muestraDatos(itm){
            if (itm.contenido != '-')
            this.$swal.fire({
                html:
                '<p>Registrado: '+itm.dateCreated+' por '+itm.creado+'</p>'
            })
            //console.log(itm)
        },



        getColor(turno){

            let text = ''
            if (turno){
                //console.log(turno.turno)
                switch(turno.turno){
                    case 'mat':
                        text = 'blue'
                        break
                    case 'ves':
                        text = 'green'
                        break
                    case 'noc':
                        text = 'red'
                        break
                    default:
                        text = ''
                        break
                }
            }
            //console.log(text)
            return text
        }
    },
    computed:{
        datosTable(){
            let grande = Math.max(this.datos.diag.length, this.datos.res.length, this.datos.int.length)
            let datosTodos = []
            if (grande > 0){
                for (let i = 0; i<grande; i++){

                    let arr = {
                        diag:{
                            contenido:'-',
                            creado:'-',
                            dateCreated:'-',
                            fecha:'-',
                            id:'-',
                            internamiento:'-',
                            paciente:'-',
                            tipo:'-',
                            turno:'-',
                        },
                        res:{
                            contenido:'-',
                            creado:'-',
                            dateCreated:'-',
                            fecha:'-',
                            id:'-',
                            internamiento:'-',
                            paciente:'-',
                            tipo:'-',
                            turno:'-',
                        },
                        int:{
                            contenido:'-',
                            creado:'-',
                            dateCreated:'-',
                            fecha:'-',
                            id:'-',
                            internamiento:'-',
                            paciente:'-',
                            tipo:'-',
                            turno:'-',
                        }
                    }
                    this.datos.diag[i]?arr.diag = this.datos.diag[i]:arr.diag.contenido = '-'
                    this.datos.res[i]?arr.res = this.datos.res[i]:arr.res.contenido = '-'
                    this.datos.int[i]?arr.int = this.datos.int[i]:arr.int.contenido = '-'
                    datosTodos.push(arr)
                }
            }
            

            return datosTodos
        }
    },

}
</script>

<style>

.textoarriba{
    vertical-align: top;

}
.tablaDiag{
    border: 1px solid  #ECECEC;
    border-spacing: 0.5rem;
    border-collapse: collapse;
}
</style>