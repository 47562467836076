<template>
    <v-card>
        
        <v-card-title :class="turnoColor+' lighten-3'">
            {{turnoCompleto}}
            
            <v-progress-linear
                v-if="resultadosLoading"
                :active="resultadosLoading"
                :indeterminate="resultadosLoading"
                absolute
                bottom
                color="white"
            ></v-progress-linear>

            <v-spacer></v-spacer>
            <v-btn
                :disabled="agregaRes"
                small
                color="green"
                dark
                rounded
                @click="agregaRes = true"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-expand-transition>
                <div v-if="agregaRes">
                    <v-textarea
                        v-model="resultado"
                        class="py-2"
                        outlined
                        filled
                        dense
                        hide-details
                        background-color="white"
                        label="Nuevo resultado"
                    ></v-textarea>

                    <v-text-field
                        v-model="codigos"
                        rounded
                        outlined
                        dense
                        label="Códigos del CMGPC de las GPCE"
                    ></v-text-field>
                    
                    <v-btn
                        class="mr-1"
                        text
                        color="red"
                        dark
                        rounded
                        
                        @click="cancela"
                    >
                        Cancelar
                    </v-btn>

                    <v-btn
                        class="ml-1 white--text"
                        color="green"
                        
                        rounded
                        :disabled="!datosTodos"
                        :loading="agregaLoading"
                        @click="guarda"
                    >
                        Agregar
                    </v-btn>
                </div>
            </v-expand-transition>

            
            <v-list two-line subheader>
                <transition-group name="list" tag="p">

                    <v-list-item v-for="item in resultadosTodos" :key="item.id">
                        <v-list-item-content >
                        
                            <v-list-item-title>
                                <v-icon
                                    small
                                    color="red "
                                    v-if="tieneRol('ROLE_ENFERMERIA_ADMIN')||tieneRol('ROLE_ADMIN')"
                                    @click="borraEntrada(item.id)">
                                        mdi-eraser
                                </v-icon>
                                <span style="white-space: pre-wrap">{{item.resultado}}</span></v-list-item-title>
                            <v-list-item-subtitle >
                                <span style="white-space: pre-wrap">{{item.codigos}}</span>
                            </v-list-item-subtitle>
                            
                            <v-divider/>
                        </v-list-item-content>
                    </v-list-item>
                </transition-group>
            </v-list>









        </v-card-text>
    
    </v-card>
</template>

<script>
export default {
    mounted(){
        this.carga()
    },
    props:{
        turno: String,
        estaFecha: String,
        internamiento: Number,
        
    },
    data:()=>({
        resultado:'',
        codigos:'',
        agregaRes:false,
        agregaLoading:false,
        resultadosLoading:false,
        resultadosTodos:[],
    }),
    methods:{


        tieneRol(rol){
            let contiene = this.$store.state.security.roles.includes(rol)
            return (contiene)
        },

        async borra2(id,motivo){
            try{
                let borra = await this.$http({
                    method:'DELETE',
                    url:'/enfermeria/borraResultados',
                    data:{id:id, motivo:motivo}
                })
                if (borra.data.estado === true){
                    this.carga()
                } else {
                    console.log(borra.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede eliminar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        borraEntrada(id){
            //let motivo 
            this.$swal.fire({
                title: 'Borrar esta entrada',
                text: 'Motivo para borrar esta entrada',
                input: 'text',
                //inputValue:motivo,
                confirmButtonText: 'Borrar registro',
                showLoaderOnConformation: true,
                inputValidator:  (value) => {
                    if (value) {

                        this.$swal.fire({
                            title:'¿Desea borrar?',
                            text:'Esta acclón no se puede deshacer',
                            icon: 'warning',
                            showCancelButton: true
                        }).then((result)=>{
                            if (result.value){
                                this.borra2(id,value)
                            }
                        })
                    } else {
                        return 'Indique el motivo para esta eliminación'
                    }
                }
            })
        },


        async carga(){
            
            this.resultadosLoading = true
            
            let params = {
                internamiento: this.internamiento,
                fecha: this.estaFecha,
                turno: this.turno,
            }

            try{
                let resultados = await this.$http({
                    url:'/enfermeria/listResultadosTodos',
                    method:'get',
                    params: params
                })
                this.resultadosLoading = false
                if (resultados.data.estado == true){

                    //console.log(resultados.data.root)
                    this.resultadosTodos = resultados.data.root
                    // carga los resultados

                } else {
                    //console.log(resultados.data.root)
                    this.resultadosTodos = resultados.data.root
                    this.agregaLoading = false
                }

            }catch(err){
                this.resultadosLoading = false
                this.$store.dispatch('security/revisaError',err)
            }
            
        },
        cancela(){
            this.agregaRes = false
            this.resultado=''
            this.codigos=''
        },

        async guarda(){
            this.agregaLoading = true
            let params = {
                internamiento: this.internamiento,
                turno:this.turno,
                fecha:this.estaFecha,
                resultado: this.resultado,
                codigos: this.codigos,
            }

            try{
                let resultados = await this.$http({
                    url:'/enfermeria/guardaResultados',
                    method:'POST',
                    data: params
                })

                if (resultados.data.estado == true){

                    this.resultado=''
                    this.codigos=''
        
                    this.agregaLoading = false
                    this.agregaRes = false

                    this.carga()

                } else {
                    console.log(resultados.data.root)

                    this.agregaLoading = false

                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                    
                }

            }catch(err){
                this.agregaLoading = false
                this.$store.dispatch('security/revisaError',err)
            }

        },
    },
    computed:{
        datosTodos(){
            if (this.resultado && this.codigos) {
                return true
            } else {
                return false
            }
        },
        turnoCompleto(){
            let text = ''

            switch (this.turno){
                case 'mat':
                    text = 'Matutino'
                    break
                case 'ves':
                    text = "Vespertino"
                    break
                case 'noc':
                    text = 'Nocturno'
                    break
            }

            return text
        },

        turnoColor(){
            let text = ''

            switch (this.turno){
                case 'mat':
                    text = 'blue'
                    break
                case 'ves':
                    text = "green"
                    break
                case 'noc':
                    text = 'red'
                    break
            }
            return text
        },
        
    },
    watch:{
        estaFecha(){
            //Carga la acción para cargar estados
            this.carga()
        },
    }
    
}
</script>

<style>

</style>